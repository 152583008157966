import React from "react";
import { Link } from "react-router-dom";


const SuggestedPosts = ({ className, header, posts = [] , darkMode}) => {

  return (
    <div
      className={`w-full shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] rounded-lg p-4 ${className}`}
    >
      <h2 className={`text-black font-roboto font-medium  md:text-xl`}>
        {header}
      </h2>
      <div className="grid gap-y-5 mt-5 md:grid-cols-2 md:gap-x-5 lg:grid-cols-1">
        {posts.slice(0,5).map((item) => (
          <div
            key={item.articleId}
            className="flex space-x-3 flex-nowrap items-center"
          >
            <img
              className="aspect-square object-cover rounded-lg w-1/5"
              src={item?.image}
              alt={item?.title}
            />
            <div className="text-sm font-robotofont-medium">
              <h3 className={`text-sm font-roboto text-black font-medium md:text-base lg:text-lg`}>
                <Link to={`/projects/Project SS/${item?.title}`}>{item.title}</Link>
              </h3>
              <span className={`text-xs opacity-60 text-black`}>
                {new Date(item.createdOn).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuggestedPosts;
