import React from 'react';
import MainLayout from '../../components/MainLayout';
import {AiOutlineMail, AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiOutlineTwitter} from "react-icons/ai";
import ContactAnime from './container/ContactAnime';
import {motion} from 'framer-motion';

const ContactPage = () => {
  return (
    <MainLayout> 
    <div 
     className="container  mx-auto md:px-6 ">
    <section className="mb-6">
      <div className="container px-6 md:px-12">
        <div
          className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  md:py-16 md:px-12  backdrop-blur-[30px]">
          <div className="flex flex-wrap">
            {/** Left hand side */}
            <div className="mb-12 w-full  shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6">
              
            <ContactAnime/>

            </div>
            {/** Right hand side */}
            <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12 text-black">
              <p className='text-center mb-12 '>Thank you for your interest in Enibo Albert Charity Foundation's mission. To learn more about how you can support our initiatives aligned with the SDGs in the Niger Delta Region, please connect with us:</p>
              <div className="flex flex-wrap">
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_orange">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                          stroke="currentColor" className="h-6 w-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">
                      Phone Number
                      </p>
                      
                      <p className="text-neutral-500 ">
                      +234-9036396431
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_orange">
                      <AiOutlineMail size={22} />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">
                        Email
                      </p> 
                      <p className="text-neutral-500 max-w-[100px]">
                      <a href='mailto:eniboalbertcharityfoundation@gmail.com' target='_blank' rel="noreferrer"> eniboalbertcharity foundation@gmail.com </a>
                      </p>
                    
                    </div>
                  </div>
                </div>
                <div
                  className="mb-12 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:mb-12 lg:w-full lg:px-6 xl:w-6/12">
                  <div className="align-start flex">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_orange">
                      <AiFillLinkedin size={22}/>
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">LinkedIn</p> 

                      <p className="text-neutral-500 ">
                        <a href='https://www.linkedin.com/company/enibo-albert-charity-foundation/' target='_blank' rel="noreferrer">Enibo Albert Charity Foundation </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:mb-12 xl:w-6/12">
                  <div className="align-start flex">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_orange">
                      <AiFillFacebook size={22} />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">Facebook</p>
                      <p className="text-neutral-500 ">
                      <a href='https://www.facebook.com/Enibo Albert Charity Foundation' target='_blank' rel="noreferrer"> Enibo Albert Charity Foundation</a>
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:mb-12 xl:w-6/12">
                  <div className="align-start flex">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_orange">
                      <AiFillInstagram size={22} />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">Instagram</p>
                      <p className="text-neutral-500 ">
                    <a href='https://www.instagram.com/@eniboalbertcharityfoundation' target='_blank' rel="noreferrer">@eniboalbertcharityfoundation</a> 
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:mb-12 xl:w-6/12">
                  <div className="align-start flex">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_orange">
                      <AiOutlineTwitter size={22} />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">Twitter</p>
                      <p className="text-neutral-500 ">
                      <a href='https://www.twitter.com/@EniboAlbertCFDN' target='_blank' rel="noreferrer">@EniboAlbertCFDN</a>
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    </div>
    </MainLayout>  
  )
}

export default ContactPage
