import React from 'react';
import {images} from "../constants";
import { FaFacebook,  FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section className='bg-black'>

      <footer className='container mx-auto grid grid-cols-10 px-5 py-10 gap-y-10 gap-x-10 md:pt-20 md:grid-cols-12 lg:grid-cols-12'>

        
      <div className='col-span-5 md:col-span-4 lg:col-span-2'> 
      <h3 className='text-gray-400 font-bold md:text-xl'>About Foundation</h3>
      <ul className='text-green-500  mt-5 space-y-4 text-lg'>
        
          <li>
          <Link to="/about">About us</Link> 
          </li>
          <li>
          <Link to="/enquiry/volunteer"> Join mission</Link> 
          </li>
          
         
      </ul>
      </div>
      <div className='col-span-5 md:col-span-4 lg:col-span-2'> 
      <h3 className='text-gray-400 font-bold md:text-xl'>Enquiries</h3>
      <ul className='text-green-500  mt-5 space-y-4 text-lg'>
        <li>
          <Link to="/enquiry/faq"> Faq</Link> 
          </li>
          <li>
          <Link to="/enquiry/volunteer"> Volunteer</Link> 
          </li>
          <li>
          
        </li>
      </ul>
      </div>
      <div className='col-span-5 md:col-span-4 md:col-start-5 lg:col-span-2 '> 
      <h3 className='text-gray-400 font-bold md:text-xl'>Giving</h3>
      <ul className='text-green-500 text-lg mt-5 space-y-4'>
        <li>
          <Link to="/donate">Donate</Link> 
          </li>
          <li>
          <Link to="#"> PSS</Link> 
          </li>
         
        
      </ul>
      </div>
      <div className='col-span-5 md:col-span-4 lg:col-span-2'> 
      <h3 className='text-gray-400 font-bold md:text-xl'>Publications</h3>
      <ul className='text-green-500 text-lg mt-5 space-y-4'>
        <li>
          <Link to="/article">Articles</Link> 
          </li>
          <li>
          <Link to="/contact">Contact</Link> 
          </li>
          <li>
          <Link to="#"> more...</Link> 
          </li>
         
      </ul>
      </div>
      <div className='col-span-12 mt-4 md:order-first md:col-span-4 lg:col-span-4'>
        <div className=''>
        <div className=''>
          <img src={images.logo} className='w-20 lg:w-[120px]  lg:h-[120px] brightness-0 invert  mx-auto md:mx-0' alt="logo-sm"/>
        </div>
        <p className='text-gray-300 text-sm  md:text-left md:text-base text-center'>Making lives better</p>
        <div className='flex justify-center items-center text-gray-300 space-x-4 py-8 md:justify-start'>
          <a href="https://www.instagram.com/@eniboalbertcharityfoundation" className='hover:text-eni_orange hover:animate-spin'><FaInstagram size={20}/></a>
        
          <a href="https://www.facebook.com/Enibo Albert Charity Foundation" className='hover:text-eni_orange hover:animate-spin'><FaFacebook size={20}/></a>
          <a href="https://www.twitter.com/@EniboAlbertCFDN" className='hover:text-eni_orange hover:animate-spin'><FaXTwitter size={20}/></a>
          <a href="https://www.linkedin.com/Enibo Albert Charity Foundation" className='hover:text-eni_orange hover:animate-spin'><FaLinkedin size={20}/></a>
        </div>
        </div>
      </div>
      <div className='col-span-8 lg:col-span-12  mx-auto py-4'>
        <span className='text-gray-300 mt-4'>developed by</span> <span className='text-center text-green-500 hover:text-eni_orange'>
         <a href='https://davidlawrence.onrender.com/' target='_blank'>David Lawrence © 2024 </a> 
        </span>
      </div>
     
      </footer>
    </section>
  )
}

export default Footer
