import React from 'react';
import { images } from '../constants';
import "../App.css";

const Donate = () => {
  return (
    
<div className="container max-w-[95vw] my-2 mx-auto md:px-6">
 
 <section className=" text-center lg:text-left">
   <div className="py-12  md:px-12">
     <div className="container mx-auto xl:px-32">
       <div className="grid items-center lg:grid-cols-2">
         <div className="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
           <div
             className="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px]  md:px-12 lg:-mr-14">
             <h2 className="mb-6 text-3xl font-bold"><span className="text-eni_green">We</span> <span className='text-black'>Are Accepting</span>  <span className="text-eni_orange">Support</span></h2>
             <p className="mb-8 text-neutral-500 text-justify">
             Our organization is dedicated to helping those in need. We believe that every person deserves support and assistance, and we are committed to making a positive difference in the world. We appreciate any donations that you can provide, as they will be put to good use. Your support can make a real difference in the lives of those who need it most. Thank you for your consideration. 💜

             </p>

             <div className="grid gap-x-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-2 text-black">
               <div className="mb-6">
                 <p className="flex items-center">
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                     stroke="currentColor" className="mr-3 h-5 w-5">
                     <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" /></svg>Books
                 </p>
               </div>

               <div className="mb-6">
                 <p className="flex items-center">
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                     stroke="currentColor" className="mr-3 h-5 w-5">
                     <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" /></svg>Clothes
                 </p>
               </div>

               <div className="mb-6">
                 <p className="flex items-center">
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                     stroke="currentColor" className="mr-3 h-5 w-5">
                     <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                   </svg>Shoes and bags
                 </p>
               </div>
               <div className="mb-6">
                 <p className="flex items-center">
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                     stroke="currentColor" className="mr-3 h-5 w-5">
                     <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                   </svg>Cash
                 </p>
               </div>
             </div>
           </div>
         </div>
         <div className="md:mb-12 lg:mb-0">
           <img src={images.ctaDonate}
             className="rotate-lg-6 w-full rounded-lg shadow-lg" alt="" />
         </div>
       </div>
     </div>
   </div>
 </section>

</div>

  )
}

export default Donate
