import React from 'react';
import MainLayout from '../../components/MainLayout';
import Hero from './container/Hero';
import Articles from './container/Articles';
import Stat from './container/Stat';
import WhatWeDo from './container/WhatWeDo';
import MiniAbout from './container/MiniAbout';
//import Testimony from './container/Testimony';
import OutreachStories from './container/OutreachStories';
import Newsletter from './container/Newsletter';
import { useTheme } from '../../components/ThemeContext';
import {motion} from "framer-motion";

const LandingPage = () => {
  const { darkMode, background } = useTheme();
  return (
  <div style={{ color: darkMode ? '#fff' : '#333', background }}>
  <MainLayout  darkMode={darkMode}>
   <Hero darkMode={darkMode}/>
   
   <div >
    <MiniAbout darkMode={darkMode}/>
   </div>
   
   <div >
      <Stat darkMode={darkMode}/>
    </div>
    
    <motion.div initial={{ x: -300, opacity: 0 }}  whileInView={{ x: 0, opacity: 1 }} exit={{ x: 300, opacity: 0 }} transition={{ duration: 1.1, ease: "easeInOut" }}>
      <Articles darkMode={darkMode}/>
   </motion.div>

   <div >
      <Newsletter darkMode={darkMode}/>
   </div>

   <div >
      <OutreachStories darkMode={darkMode}/>
    </div>
  
  </MainLayout>
  </div> 
  )  
}

export default LandingPage;
