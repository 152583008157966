import { motion } from "framer-motion";
import Lottie from "lottie-react";
import animationData from "../../../assets/json/enibo2.json";
import { TypeAnimation } from "react-type-animation";
import { HiLink } from "react-icons/hi";
import { Link } from "react-router-dom";


const containerVariants = {
  animate: {
    transition: {
      delayChildren: 2,
      staggerChildren: 0.6,
    },
  },
};

const MiniAbout = () => {
  return (
    <div className="flex flex-col lg:flex-row mt-20 lg:mt-[2px]  items-start lg:items-center justify-between max-[960px]:flex-col px-32 max-[960px]:px-16 max-[560px]:px-8 ">
      <div className="lg:mr-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5 }}
          className="w-full "
        >
          <div className="flex">
            <h1 className="mb-4 text-secondaryColor text-4xl uppercase font-semibold max-[960px]:text-5xl max-[560px]:text-4xl">
              ABOUT <div className="text-eni_orange font-bolder inline-block">US</div>
            </h1>
          </div>
       
          <p className="py-2 text-lg lg:text-xl  text-neutral-500 mt-4 text-justify" style={{ maxWidth: '650px' }}>
          Enibo Albert Charity Foundation is a non-profit organization dedicated to improving access to education and empowering children and families in the Niger Delta region. Our innovative programs provide educational resources, foster community partnerships, and advocate for policies that create a more equitable society. We believe that every child deserves the opportunity to succeed, and we work tirelessly to make that a reality. Through our efforts, we hope to create a brighter future for the next generation.

          </p>
          <div className="text-sm text-neutral-500 lg:text-xl lg:mb-6 font-light">
            <TypeAnimation
              sequence={[
                "Expand access to quality education and provide school supplies to children in underserved communities",
                1000,
                "Increase support for basic needs, including food, shelter, healthcare, and clothing",
                1000,
                "Raise awareness and advocate for social justice issues through creative campaigns and community engagement",
                1000,
                "Collaborate with partners to create sustainable solutions that address systemic inequalities and promote equity",
                1000,
                "Empower individuals to become change-makers and agents of positive change through education, skills development, and advocacy training",
                1000,
      
              ]}
              wrapper="span"
              speed={60}
              style={{  display: "inline-block" }}
              repeat={Infinity}
            />
          </div>
            <div className="mt-6 flex items-center space-x-6">
            
            <Link to="/about" spy={true} smooth={true} offset={-100}>
            <div className="flex items-center  hover:text-eni_orange">
              <button className="text-gray-600 font-bold text-md uppercase hover:text-eni_orange">
               Learn More
              </button>
              <HiLink className="text-mainColor text-2xl ml-2 animate-spin" />
            </div>
            </Link>
          </div>
        </motion.div>
      </div>
      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
        className="mt-6  justify-center lg:max-w-[400px]"
      >
        <Lottie animationData={animationData} />
      </motion.div>
    </div>
  );
};

export default MiniAbout;
