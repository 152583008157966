import React from 'react';
import MainLayout from '../../components/MainLayout';
import Articles from './container/Articles';
import PagesMiniHero from "../../components/PagesMiniHero";
import { useTheme } from '../../components/ThemeContext';
import { motion } from 'framer-motion';

const ArticlePage = () => {
  const {darkMode} = useTheme();
  const intro = {text:
    'Events and Happenings around Project Smart Seed' 
    };
  return (
    <MainLayout>
    <PagesMiniHero pageTitle="Project Smart Seed" PageIntro={intro.text} darkMode={darkMode}/>
    <motion.div initial={{ x: 300, opacity: 0 }}  whileInView={{ x: 0, opacity: 1 }} exit={{ x: -300, opacity: 0 }} transition={{ duration: 1.3, ease: "easeInOut" }}>
     <Articles darkMode={darkMode}/>
    </motion.div>
    
    </MainLayout>
    
  )
}

export default ArticlePage
