import React from 'react';
import "../../../App.css";
import { images } from '../../../constants';

const Stat = () => {
  return (    
<section className="mb-8 text-center lg:text-left lg:mt-12">
  <div className="container mx-auto text-center lg:text-left xl:px-32">
    <div className="grid items-center lg:grid-cols-2">
      <div className="mb-12 lg:mb-0">
        <div
          className="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  md:px-12 lg:-mr-14 backdrop-blur-[30px]">
          <h2 className="mb-6 text-4xl font-bold">Our <span className="text-eni_orange">Impact?</span></h2>
          <p className="mb-12 text-neutral-500 text-lg">
          In less than a year, the Enibo Foundation has deployed humanitarian support in over 20 communities, especially during the devastating flood of 2022
          </p>

          <div className="grid gap-x-6 md:grid-cols-2">
            <div className="mb-12 md:mb-4">
              <h2 className="text-eni_green mb-4 text-3xl font-bold">20+</h2>
              <h5 className="mb-0 text-lg font-medium text-neutral-500 ">
                Communities Reached
              </h5>
            </div>

            <div className="mb-12 md:mb-4 text-center">
              <h2 className="text-eni_green mb-4 text-3xl font-bold">2</h2>
              <h5 className="mb-0 text-lg font-medium text-neutral-500 ">
                Awards 
              </h5>
            </div>

            <div className="mb-12 md:mb-0">
              <h2 className="text-eni_green mb-4 text-3xl font-bold">3000+</h2>
              <h5 className="mb-0 text-lg font-medium text-neutral-500 ">
                People Reached 
              </h5>
            </div>

            <div className=" text-center">
              <h2 className="text-eni_green mb-4 text-3xl font-bold">300+</h2>
              <h5 className="mb-0 text-lg font-medium text-neutral-500 ">
                Children Gifted with learning Materials
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div>
        <img src={images.eniboImg}
          className="fancy-border-radius rotate-lg-6 w-[95vw] shadow-lg " alt="" />
      </div>
    </div>
  </div>

</section>
 
  )
}

export default Stat
