import React from 'react';
import {Carousel } from 'antd';
import { images } from '../../../constants';
import HeroCardV2 from './HeroCardV2';

const Hero = () => {
  const  herotitle1= "Expand Access"
  const  herotext1= "Expand access to quality education and provide school supplies to children in underserved communities."
  const  herotitle2= "Increase Support"
  const  herotext2= "Increase support for basic needs, including food, shelter, healthcare, and clothing."
  const  herotitle3= "Raise Awareness"
  const  herotext3= "Raise awareness and advocate for social justice issues through creative campaigns and community engagement."
  const  herotitle4= "Collaborate with Partners"
  const  herotext4= "Collaborate with partners to create sustainable solutions that address systemic inequalities and promote equity."
  const  herotitle5= "Empower Individuals"
  const  herotext5= "Empower individuals to become change-makers and agents of positive change through education, skills development, and advocacy training."
  return (
  <section className='md:min-h-screen lg:min-h-screen xl:min-h-screen max-h-screen text-white mb-10px md:mb-[180px] lg:mb-[100px] rounded-lg'>
    <Carousel 
    autoplay
    draggable={true} 
    dots={true}
    dotPosition='bottom'
    autoplaySpeed={5000}>
    <HeroCardV2 image={images.enibobanner2} text={herotext1} title={herotitle1}/>
    <HeroCardV2 image={images.eacf_banner1} text={herotext5} title={herotitle5}/>
    <HeroCardV2 image={images.enibobanner} text={herotext2} title={herotitle2}/>
    <HeroCardV2 image={images.enibo1} text={herotext3} title={herotitle3}/>
    <HeroCardV2 image={images.enibokids1} text={herotext4} title={herotitle4}/>
   
   </Carousel>
    </section>

  )
}

export default Hero
