import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Routes, Route, } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import './App.css';
import LandingPage from './pages/home/LandingPage';
import LoginPage from './pages/login/LoginPage';
import AboutPage from './pages/about/AboutPage';
import ContactPage from './pages/contact/ContactPage';
import ArticlePage from './pages/article/ArticlePage';
import ArticleDetailPage from './pages/articleDetail/ArticleDetailPage';

import RuralProjectPage from './pages/ruralproject/ArticlePage';
import RuralProjectDetailPage from './pages/ruralprojectDetail/ArticleDetailPage';

import SmartProjectPage from './pages/smartproject/ArticlePage';
import SmartProjectDetailPage from './pages/smartprojectDetail/ArticleDetailPage';

import AdminLayout from "./pages/admin/AdminLayout";
import Admin from "./pages/admin/screens/Admin";

//youtube 
import EditYoutubeV2 from './pages/admin/screens/youtube/EditYoutubeV2';
import ManageYoutubes from './pages/admin/screens/youtube/ManageYoutubes';

//articles and post
import PostTest from './pages/admin/screens/posts/PostTest';
import ManagePosts from "./pages/admin/screens/posts/ManagePosts";
import EditPostV2 from './pages/admin/screens/posts/EditPostV2';

//projects
import ProjectTest from './pages/admin/screens/projects/PostTest';
import ManageProjects from "./pages/admin/screens/projects/ManagePosts";
import EditProjectV2 from './pages/admin/screens/projects/EditPostV2';

//outreaches
import OutreachTest from './pages/admin/screens/outreach/OutreachTest';
import ManageOutreaches from "./pages/admin/screens/outreach/ManageOutreaches";
import EditOutreachV2 from './pages/admin/screens/outreach/EditOutreachV2';



import FaqPage from './pages/faq/FaqPage';
import VolunteerPage from './pages/volunteer/VolunteerPage';
import DonatePage from './pages/donate/DonatePage';
import { ThemeProvider } from './components/ThemeContext';

function App() {
  
  return (
    <div className={`App font-firacode  bg-offwhite  `}>
        <ThemeProvider>
        <ScrollToTop/>
        <Routes>
          <Route index path="/" exact element={ <LandingPage/>} />
          <Route  path="/about" element={ <AboutPage/>} />
          <Route  path="/donate" element={ <DonatePage/>} />
          <Route index path="/enquiry/faq" element={ <FaqPage/>} />
          <Route index path="/enquiry/volunteer" element={ <VolunteerPage/>} />
          <Route  path="/articles/:id" element={ <ArticleDetailPage/>} />
          <Route  path="/article" element={ <ArticlePage/>} />

          <Route  path="/projects/Rural Impact/:id" element={ <RuralProjectDetailPage/>} />
          <Route  path="/project/rural" element={ <RuralProjectPage/>} />

          <Route  path="/projects/Project SS/:id" element={ <SmartProjectDetailPage/>} />
          <Route  path="/project/smart" element={ <SmartProjectPage/>} />
          
          <Route  path="/contact" element={ <ContactPage/>} />
          <Route path="/n1/eacf/adm" element={<LoginPage />}> </Route>
          <Route path ="/admin" element={<AdminLayout/>} >
              <Route index element={<Admin/>} />
              <Route path="youtube/manage" element={<ManageYoutubes />} />
              <Route path="youtube/manage/edit/:id" element={<EditYoutubeV2 />} />
             
              <Route path="posts/new" element={<PostTest />} />
              <Route path="posts/manage" element={<ManagePosts />} />
              <Route path="posts/manage/edit/:id" element={<EditPostV2 />} />

              <Route path="project/new" element={<ProjectTest />} />
              <Route path="project/manage" element={<ManageProjects />} />
              <Route path="project/manage/edit/:id" element={<EditProjectV2 />} />

              <Route path="outreach/new" element={<OutreachTest />} />
              <Route path="outreach/manage" element={<ManageOutreaches />} />
              <Route path="outreach/manage/edit/:id" element={<EditOutreachV2 />} />
          </Route>
        </Routes>
      <Toaster/>
      </ThemeProvider>
    </div>
  );
}

export default App;
