import logo from "../assets/logo.png";
import david from "../assets/posts/david.jpg";
import victor from "../assets/posts/victor.jpg";
import doubra from "../assets/posts/doubra.jpg";
import marian from "../assets/posts/marian.jpg";
import ifeanyi from "../assets/posts/ifeanyi.jpg";
import nabena from "../assets/posts/nabenaaa.jpg";
import enibobanner from "../assets/eniboBANNER.jpeg";
import enibobanner2 from "../assets/enibo-.jpeg";
import eniboImg from "../assets/enibojpg.jpeg";
import enibobanner3 from "../assets/enibo background.jpg";
import enibobanner4 from "../assets/enib12.jpeg";
import enibo1 from "../assets/eniboooo.jpeg";
import enibokids from "../assets/enibokids.jpeg";
import enibokids1 from "../assets/enibokids3.jpeg";
import enibokids2 from "../assets/enibokids2.jpeg";
import ctaDonate from "../assets/enibo_donate.jpg";
import eniboVolunteer from "../assets/enibo_hiring.jpeg";
import eacf_banner1 from "../assets/eacf_banner1.jpg";
import childrenDay from "../assets/posts/childrenDay.jpg";
import postflood from "../assets/posts/post-flood.jpg";
import chriswonder from "../assets/posts/christwonder.jpg";
import bayelsafood from "../assets/posts/bayelsafoodfestival.jpg";
import ruralimpact from "../assets/posts/ruralimpact.jpg";
import learningmaterial from "../assets/posts/learningmaterials.jpg";




const images = {
    enibobanner,enibobanner2,enibobanner3,enibobanner4,enibo1,enibokids,enibokids1,enibokids2,ctaDonate,eniboVolunteer,eniboImg, logo, david,doubra,marian,victor,ifeanyi,nabena,eacf_banner1,
    childrenDay,postflood,chriswonder,bayelsafood,ruralimpact,learningmaterial
     
}

export default images;