import React from 'react';
import MainLayout from '../../components/MainLayout';
import  {images}  from '../../constants';
import { motion } from 'framer-motion';
const FaqPage = () => {
  return (
    <MainLayout>
    <div 
    className="container my-20 mx-auto md:px-6">
    
      <section className="mb-6">
      
        <div className="container mx-auto text-center lg:text-left xl:px-32">
          <div className=" grid items-start lg:grid-cols-2">
            <div className="mb-12 lg:mb-0">
              <div
                className="block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  md:px-12 lg:-mr-14 backdrop-blur-[30px]">
                <h3 className="mb-3 text-2xl font-bold text-black">
                  We know how valuable your time is
                </h3>
                <h5 className="mb-12 text-lg font-bold text-eni_orange  lg:mb-10 xl:mb-12">
                  Let us answer your questions
                </h5>

                <p className="mb-4 font-bold text-black">
                How can I get involved or volunteer with your NGO?
                </p>
                <p className="mb-6 text-gray-500 ">
                We welcome volunteers who are passionate about our cause and want to contribute their time and skills. You can get involved by visiting our website and filling out the volunteer form. 
                <br/><br/>
                We have various opportunities ranging from assisting in education programs, organizing events, Grant writing, partnership coordinator, fundraising, and administrative tasks.
                </p>

                <p className="mb-4 font-bold text-black">
                What specific projects or programs does your NGO undertake?

                </p>
                <p className="mb-6 text-gray-500 ">
                Our NGO has several projects we undertake: <br/>
                  <ol type="1">
                    <li>1. Providing access to quality education by providing educational resources and scholarships to underprivileged children</li>
                    <li>2. Distributing school supplies to children in underserved communities.</li>
                    <li>3. Supporting basic needs like food, shelter, healthcare, and clothing for vulnerable children and families</li>
                    <li>4. Advocating for social justice issues through campaigns and community engagement.</li>
                  </ol>
                </p>

                <p className="mb-4 font-bold text-black">
                How can I donate or contribute financially to support your NGO's work?
                </p>
                <p className="text-gray-500 mb-6">
                You can contribute financially to support our NGO's work by donating via bank transfers or checks. Additionally, we actively seek partnerships and collaborations with organizations and individuals who share our vision and can provide financial support for any of our ongoing projects.
                </p>

                <p className="mb-4 font-bold text-black">
                What impact has your NGO made in the community or target area?
                </p>
                <p className="mb-6 text-gray-500 ">
                Our NGO has made a significant impact in the community and target area by: <br/>
                  <ol type="1">
                    <li>1. Deploying humanitarian support to over 20 communities, especially during the devastating flood of 2022.</li>
                    <li>2. Providing educational resources and school supplies to hundreds of children, enabling them to access quality education.</li>
                    <li>3. Supporting basic needs for vulnerable individuals and families, ensuring their well-being and dignity.</li>
                    <li>4. Raising awareness about social justice issues through campaigns and community engagement, mobilizing support and creating positive change.</li>
                  </ol>
                </p>
                <span className="italic font-bold text-black">Please note that these are general answers to common questions, and for more specific information or inquiries, we encourage individuals to reach out to our NGO directly.</span>
              </div>
            
            </div>

            <div>
              <img src={images.enibobanner}
                className="w-full rounded-lg shadow-lg " alt="" />
            </div>
          </div>
        </div>
      
      </section>

    </div>


    </MainLayout>
    
  )
}

export default FaqPage
