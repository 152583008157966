import React from 'react';
import MainLayout from '../../components/MainLayout';
import AboutIntro from './container/AboutIntro';
import {AiOutlineCheck} from "react-icons/ai";
import { motion } from 'framer-motion';

const AboutPage = () => {
  return (
    <MainLayout>  
<div className="container max-w-[90vw]  mt-24 mx-auto md:px-6">
 <AboutIntro/>

 <section 
 className="mb-6">
<div className='text-center text-2xl text-black my-6 font-bold uppercase underline'> Goal And Objectives</div>
   <div className="grid gap-x-6 md:grid-cols-2 lg:grid-cols-4 xl:gap-x-12">
     <div className="mb-12">
       <div className="flex">
         <div className="shrink-0">
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
             stroke="currentColor" className="mr-3 h-5 w-5 text-success">
             <path stroke-linecap="round" stroke-linejoin="round"
               d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
           </svg>
         </div>
         <div className="ml-2 grow">
           <p className="mb-1 font-bold text-black">Expand Access</p>
           <p className="text-neutral-500 ">
            <ol type={1} >
              <li>Expand access to quality education and provide school supplies to children in underserved communities
              </li><br/>
              

            </ol>
            
           </p>
           <p className="text-neutral-500 ">
           
           </p>
         </div>
       </div>
     </div>

     <div className="mb-12">
       <div className="flex">
         <div className="shrink-0">
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
             stroke="currentColor" className="mr-3 h-5 w-5 text-success">
             <path stroke-linecap="round" stroke-linejoin="round"
               d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
           </svg>
         </div>
         <div className="ml-2 grow">
           <p className="mb-1 font-bold text-black">Increase Support</p>
           <p className="text-neutral-500">
           Increase support for basic needs, including food, shelter, healthcare, and clothing
           </p><br/>
         </div>
       </div>
     </div>

     <div className="mb-12">
       <div className="flex">
         <div className="shrink-0">
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
             stroke="currentColor" className="mr-3 h-5 w-5 text-success">
             <path stroke-linecap="round" stroke-linejoin="round"
               d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
           </svg>
         </div>
         <div className="ml-2 grow">
           <p className="mb-1 font-bold text-black">Raise Awareness</p>
           <p className="text-neutral-500 ">
           Raise awareness and advocate for social justice issues through creative campaigns and community engagement.
           </p><br/>
          
         </div>
       </div>
     </div>
     <div className="mb-12">
       <div className="flex">
         <div className="shrink-0">
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
             stroke="currentColor" className="mr-3 h-5 w-5 text-success">
             <path stroke-linecap="round" stroke-linejoin="round"
               d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
           </svg>
         </div>
         <div className="ml-2 grow">
           <p className="mb-1 font-bold text-black">Collaborate</p>
           <p className="text-neutral-500 ">
           Collaborate with partners to create sustainable solutions that address systemic inequalities and promote equity
           </p><br/>           
         </div>
       </div>
     </div>
     <div className="mb-12">
       <div className="flex">
         <div className="shrink-0">
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
             stroke="currentColor" className="mr-3 h-5 w-5 text-success">
             <path stroke-linecap="round" stroke-linejoin="round"
               d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
           </svg>
         </div>
         <div className="ml-2 grow">
           <p className="mb-1 font-bold text-black">Empower Individuals</p>
           <p className="text-neutral-500 ">
           Empower individuals to become change-makers and agents of positive change through education, skills development, and advocacy training.
           </p><br/>           
         </div>
       </div>
     </div>
     

    
   </div>

   <div className="flex justify-center">
     <div className="max-w-[700px] text-center">
       <p className="mb-6 font-bold uppercase underline text-black  text-2xl">
         Our Impact
       </p>
      
       <p className="mb-4 text-neutral-500 ">
       In less than a year, the Enibo Foundation has deployed humanitarian
        support in over 20 communities, especially during the devastating 
        flood of 2022.
       </p>
       <p className="mb-16 text-neutral-500 ">
       We have received recognition and awards for our outstanding
        contributions to improving the lives of individuals and communities.
       </p>
     </div>
   </div>
 </section>
 
</div>
    </MainLayout>
    
  )
}

export default AboutPage
