import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Editor from "../../../../components/editor_new/Editor";
import { createNewPost } from "../../../../services/posts"; // Import the createNewPost endpoint

const NewPost = () => {
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate } = useMutation({
    mutationFn: ({ title,public_id, content, image, token }) =>
      createNewPost({ title,public_id, content, image, token }), // Use the createNewPost endpoint
    onSuccess: () => {
      queryClient.invalidateQueries("posts");
      toast.success("Post created successfully");
      setIsLoading(false);
      navigate(`/admin/posts/manage`, { replace: true });
    },
    onError: (error) => {
      toast.error(error.message);
      setIsLoading(false);
    },
  });

  const handleCreatePost = async () => {
    try {
      setIsLoading(true);

      // Upload the file to Cloudinary
      //const imageUrl = await uploadToCloudinary(fileInput);
      const data = await uploadToCloudinary(fileInput);
      const imageUrl = data.secure_url;
      //const imagePublic_id = data.public_id;
      console.log(data)
      // Post data to create a new post
      await mutate({
        title,
        content,
        image: imageUrl,
        public_id: data.public_id,
        token: userState.userInfo?.data?.token,
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const uploadToCloudinary = async (file) => {
    const cloudName = process.env.REACT_APP_CLOUD_NAME;
     // Replace with your Cloudinary upload preset
     const uploadPreset = process.env.REACT_APP_CLOUD_UPLOAD_PRESET; 
     

    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", uploadPreset);

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      //const imageUrl = data.secure_url;
      //return imageUrl;
      console.log(data);
      return data;
    }
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];

    setFileInput(selectedFile);

    // Display a thumbnail preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
  };
  const isFormValid = title.trim() !== "" && content.trim() !== "" && fileInput !== null;
  return (
    <div>
      <div className="text-gray-600"> CREATE POST </div>
      
      <section className="container  mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
        <div className="flex-1 ">
          <div className="d-form-control w-3/4 mb-6">
            <label className="d-label" htmlFor="title">
              <span className="d-label-text mr-2 dark:text-gray-900">Title: </span>
            </label>
            <input
              type="text"
              className="d-input text-sm lg:text-md font-medium font-roboto text-dark-hard dark:text-gray-700 dark:bg-gray-300 placeholder:text-gray-500 focus:outline-none w-full rounded-full p-2"
              placeholder="Type in Title here"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="w-full mb-6 border border-gray-300 p-2 dark:text-gray-800 z-0">
          <div className="d-label">Content:</div>
          <Editor
            content={content}
            editable={true}
            onDataChange={(data) => setContent(data)}
            
          />
        </div>
          <div className="d-form-control w-full">
            <label className="d-label" htmlFor="fileInput">
              <span className="d-label-text">Image</span>
            </label>
            <input
              type="file"
              className="mb-12 d-input d-input-bordered border-slate-300 !outline-slate-300 text-xl font-medium font-roboto text-dark-hard"
              onChange={handleFileInputChange}
            />
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Thumbnail Preview"
                className="mt-2 w-20 h-20 object-cover"
              />
            )}
          </div>
          <div className="d-form-control">
          <button
            type="button"
            onClick={handleCreatePost}
            disabled={!isFormValid || isLoading}
            className="ml-[60vw]  bg-green-500 text-white font-semibold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
          >
            Create Post
          </button>
          </div>
          
        </div>
      </section>
    </div>
  );
};

export default NewPost;
