import React from 'react';
import {FaYoutube} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { getAllYoutube } from "../../../services/youtube";

const Newsletter = () => {
  const { data: youtubeData, isLoading, isError } = useQuery({
    queryFn: () => getAllYoutube(),
    queryKey: ["youtubes"],
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    
 <section className="mb-12 text-center lg:text-left">
   <div className="py-8 md:px-6 lg:my-8">
     <div className="container mx-auto xl:px-32">
       <div className="grid items-center gap-12 lg:grid-cols-2">
         <div className="md:mt-12 lg:mt-0">
           <h1 className="mb-12 text-3xl font-bold tracking-tight md:text-4xl lg:text-5xl xl:text-5xl">
             Giving is  <br /><span className="text-eni_orange ">Therapeutic</span>
           </h1>
           <Link to="/donate">
            <button className="btn py-2 px-4 border-o rounded-lg bg-eni_orange text-white font-bold hover:bg-eni_orange/65"> Support </button>

           </Link>

         </div>
         <div className="mb-12 lg:mb-0 max-w-[95vw] mx-2">
           <div
           
             className="pt-[50px] w-full embed-responsive embed-responsive-16by9 relative  overflow-hidden rounded-lg shadow-lg "
            ><iframe className="w-full h-[200px] lg:h-[300px]"   src="https://www.youtube.com/embed/EgrBZjNu1hI?si=JginDlirXcbjYeeO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
           </div>
         </div>
       </div>
     </div>
   </div>
<h2 className='mx-auto text-center text-xl lg:text-2xl  text-black'> Trending youtube post</h2>
{ youtubeData?.data?.data.map((youtube) =>(
      <div key={youtube.id} className="mb-12 lg:mb-0 max-w-[95vw] lg:w-1/2 mx-auto ">         
                <div 
                className="pt-[50px] w-full embed-responsive embed-responsive-16by9 relative  overflow-hidden rounded-lg shadow-lg "
               ><iframe className="w-full h-[200px] lg:h-[350px]"   src={youtube.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>     
      </div>
      ))}
   <div className=' text-center text-lg md:text-xl lg:text-xl mt-8'>
    <h2 className='text-neutral-500'>See more of our videos on our youtube channel, subscribe and hit the notification bell to get updated on our latest posts</h2>
    <span>
    <a href='https://www.youtube.com/@EniboAlbertCharityFoundation' target='_blank' rel="noreferrer"> <FaYoutube className='inline-block lg:block mx-auto text-center text-red-600 hover:text-eni_orange mt-2 text-4xl lg:text-5xl'/> </a>
    </span>
   </div>
 </section>



  )
}

export default Newsletter
